<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Villa Master
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Villa Master</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                  <td>{{ index + paginationData.from }}</td>
                                  <td>{{ li.villa_name }}</td>
                                  <td>{{ li.number }}</td>
                                  <td>{{ li.price }}</td>
                                  <td>{{ li.weekend_price }}</td>
                                  <td><span class="badge " :class="(li.is_rent) ? `badge-light-success` : `badge-light-danger`">{{ (li.is_rent) ? 'Yes' : 'No' }}</span></td>
                                  <td>{{ li.created_at }}</td>
                                  <td>{{ li.updated_at }}</td>
                                  <td class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteDes(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="8" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            method="post"
            @submit.prevent="submitForm"
          >
            <input type="hidden" name="id" :v-modal="id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Villa Master
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Villa Type</label
                >
                <Select2 v-model="form.villa_type_id" :options="$store.state.villaTypeData" @change="myChangeEvent($event)" :settings="{ placeholder: 'Choose Villa Type' }" @select="mySelectEvent($event)"/>
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: villaTypeError}"
                >
                  Please Choose Villa Type.
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Villa Number</label>
                <input
                    type="text" class="form-control" name="number" v-model="form.number" id="number" placeholder="Villa Number" @keyup="
                    form.number.toString().length > 0
                        ? [(numberError = 'none'), (disabled = false)]
                        : (numberError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: numberError}" > Please enter Villa Number. </div>
             </div>
             <div class="mb-1">
                <label class="form-label" for="basic-addon-name">This Villa For Rent ?</label>
                <div class="demo-inline-spacing">
                    <div class="form-check form-check-inline  mt-1">
                        <input class="form-check-input" type="radio" name="is_rent" v-model="form.is_rent" id="inlineRadio1" value="1"  />
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline  mt-1">
                        <input class="form-check-input" type="radio" name="is_rent" v-model="form.is_rent" id="inlineRadio2" value="0" />
                        <label class="form-check-label" for="inlineRadio2">No</label>
                    </div>
                </div>
            </div>
              
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Select2 from 'vue3-select2-component';
  import Form from 'vform';
  
  export default {
    name: "Villa",
    components: {
      Pagination: LaravelVuePagination,
      Select2,
    },
    data() {
      return {
        form: new Form({
            id: null,
            villa_type_id: "",
            number: "",
            is_rent: "",
        }),
        response: "",
        disabled: false,
        header: [],
        list: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        villaTypeError: "none",
        numberError: "none",
        rentError: "none",
        priceError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token')
      };
    },
    async mounted() {
      this.header = [
        "Sr. no.",
        "Villa Type Name",
        "Villa Number",
        "Price",
        "Weekend Price",
        "This Villa For Rent ?",
        "Created Date",
        "Updated Date",
        "Action",
      ];
      this.$store.dispatch('getVillaTypeList');
      this.getResults();
    },
    methods: {
      mySelectEvent({ id }) {
        this.villa_type_id = id;
        this.disabled = false;
        if(this.villa_type_id.length > 0) {
          this.villaTypeError = "none";
        }
      },
      async add() {
        (this.form.id = null),
        (this.form.villa_type_id = ""),
        (this.form.number = ""),
        (this.form.is_rent = ""),
        (this.response = "");
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async getResults(page = 1) {
        await axios.get(process.env.VUE_APP_API_URL+"/villa_master?page=" + page, 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      async submitForm() {
        this.disabled = true;
        if (this.form.villa_type_id.length === 0) {
          this.villaTypeError = "block";
        } else if (this.form.number.toString().length === 0) {
          this.numberError = "block";
        } else {
          this.villaTypeError = "none";

          if (this.form.id) {
            await this.form.put(process.env.VUE_APP_API_URL+"/villa_master/" + this.form.id,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            })
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.isShow = ""
                this.isDisplay = "none"
                this.disabled = false;
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
          } else {
            await this.form.post(process.env.VUE_APP_API_URL+"/villa_master",
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              })
              .then((response) => {
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                  this.isShow = ""
                  this.isDisplay = "none"
                  this.disabled = false;
                  this.getResults();
                } else {
                  this.$toast.error(response.data.error?.[0]);
                  this.disabled = false;
                }
              })
              .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
              });
          }
        }
      },
      deleteDes(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(process.env.VUE_APP_API_URL+"/villa_master/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.tags = [];
        this.villaTypeError = "none";
        this.numberError = "none";
        this.priceError = "none"; 
        this.disabled = false

        const output = await axios.get(process.env.VUE_APP_API_URL+"/villa_master" +(id ? "/" + id : ""),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                });
      
        this.form.id = output.data.data?.id;
        this.form.villa_type_id = output.data.data?.villa_type_id;
        this.form.number = output.data.data?.number;
        this.form.is_rent = output.data.data?.is_rent;
                
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/villa_master/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.data;
              this.isShow = ""
              this.isDisplay = "none"
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
    },
  };
  </script>